// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/primitives/StepNumber.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/primitives/StepNumber.tsx");
  import.meta.hot.lastModified = "1716561031862.5706";
}
// REMIX HMR END

import { cn } from "~/utils/cn";
import { Header2 } from "./Headers";
import { Spinner } from "./Spinner";
import { CheckIcon } from "@heroicons/react/24/solid";
export function StepNumber({
  stepNumber,
  active = false,
  complete = false,
  displaySpinner = false,
  title,
  className
}) {
  return <div className={cn("mr-3", className)}>
      {active ? <div className="flex items-center gap-x-3">
          <span className="flex h-6 w-6 items-center justify-center rounded bg-success py-1 text-xs font-semibold text-charcoal-900">
            {stepNumber}
          </span>
          <Header2>{title}</Header2>
        </div> : <div className="flex items-center gap-x-3">
          <span className="flex h-6 w-6 items-center justify-center rounded border border-charcoal-700 bg-charcoal-800 py-1 text-xs font-semibold text-text-dimmed">
            {complete ? <CheckIcon className="size-4" /> : stepNumber}
          </span>

          {displaySpinner ? <div className="flex items-center gap-x-2">
              <Header2>{title}</Header2>
              <Spinner />
            </div> : <Header2>{title}</Header2>}
        </div>}
    </div>;
}
_c = StepNumber;
var _c;
$RefreshReg$(_c, "StepNumber");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;